import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import hu from "./hu.json";
import it from "./it.json";
import no from "./no.json";
import pt from "./pt.json";
import ro from "./ro.json";
import sk from "./sk.json";

const messages = {
	de,
	en,
	es,
	fr,
	hu,
	it,
	no,
	pt,
	ro,
	sk
};

export default messages;