import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Error from "@/views/error/Error.vue";
import RequestPlan from "@/views/partnership-parent-view/children-views/request-plan/RequestPlan.vue";
import VerifyAccount from "@/views/verify-account/VerifyAccount.vue";
import RegisterAccount from "@/views/register-account/RegisterAccount.vue";
import PublicContextGuard from "@/router/guards/PublicContextGuard";
import ForgotPassword from "@/views/forgot-password/ForgotPassword.vue";
import RedirectAuthenticated from "@/views/redirect-authenticated/RedirectAuthenticated.vue";
import CreatedAccountSuccessfully from "@/views/created-account-successfully/CreatedAccountSuccessfully.vue";
import PasswordResetAuthorized from "@/views/password-reset-authorized/PasswordResetAuthorized.vue";
import ForgotPasswordVerifyCode from "@/views/forgot-password-verify-code/ForgotPasswordVerifyCode.vue";
import PartnershipSelection from "@/views/partnership-selection/PartnershipSelection.vue";
import UpdateUserData from "@/views/update-user-data/UpdateUserData.vue";
import PrivateContextGuard from "@/router/guards/PrivateContextGuard";
import ContactFormulary from "@/views/contact-formulary/ContactFormulary.vue";
import UserPilgrimTrips from "@/views/partnership-parent-view/children-views/user-pilgrim-trips/UserPilgrimTrips.vue";
import SearchPointsOfInterest from "@/views/partnership-parent-view/children-views/search-points-of-interest/SearchPointsOfInterest.vue";
import ViewNarrative from "@/views/partnership-parent-view/children-views/view-narrative/ViewNarrative.vue";
import SearchPilgrimTrips from "@/views/partnership-parent-view/children-views/search-pilgrim-trips/SearchPilgrimTrips.vue";
import SearchFeaturedTrips from "@/views/partnership-parent-view/children-views/search-featured-trips/SearchFeaturedTrips.vue";
import TripFeaturedPreview from "@/views/partnership-parent-view/children-views/trip-featured-preview/TripFeaturedPreview.vue";
import CookiesPolicy from "@/views/legal-texts/cookies-policy/CookiesPolicy.vue";
import PrivacyPolicy from "@/views/legal-texts/privacy-policy/PrivacyPolicy.vue";
import TermsOfUse from "@/views/legal-texts/terms-of-use/TermsOfUse.vue";
import CopyrightPolicy from "@/views/legal-texts/copyright-policy/CopyrightPolicy.vue";
import PartnershipParentView from '../views/partnership-parent-view/PartnershipParentView.vue';
import CheckPartnership from "@/views/check-partnership/CheckPartnership.vue";
import PointOfInterestDataById from "@/views/partnership-parent-view/children-views/point-of-interest-data/PointOfInterestDataById.vue";
import PointOfInterestDataBySlug from "@/views/partnership-parent-view/children-views/point-of-interest-data/PointOfInterestDataBySlug.vue";
import LogoutView from '../views/logout/LogoutView.vue';
import TripPilgrimCreation from "@/views/partnership-parent-view/children-views/trip-pilgrim-creation/TripPilgrimCreation.vue";
import TripPilgrimPreview from "@/views/partnership-parent-view/children-views/trip-pilgrim-preview/TripPilgrimPreview.vue";
import ExploreOnMap from "@/views/partnership-parent-view/children-views/explore-on-map/ExploreOnMap.vue";
import SearchNarratives from "@/views/partnership-parent-view/children-views/search-narratives/SearchNarratives.vue";
import DownloadApp from "@/views/partnership-parent-view/children-views/download-app/DownloadApp.vue";
import Vendors from "@/views/partnership-parent-view/children-views/vendors/Vendors.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/:partnershipSlug/:languageIsoCode?/view/',
		component: PartnershipParentView,
		children: [
			{
				path: 'point-of-interest/:pointOfInterestId',
				name: 'pointOfInterestDataById',
				component: PointOfInterestDataById,
				meta: {
					routeTrackingId: 'POINT_OF_INTEREST_PREVIEW_ID',
					headerCollapse: true
				}
			},
			{
				path: 'point-of-interest-info/:pointOfInterestSlug',
				name: 'pointOfInterestDataBySlug',
				component: PointOfInterestDataBySlug,
				meta: {
					routeTrackingId: 'POINT_OF_INTEREST_PREVIEW_SLUG',
					headerCollapse: true
				}
			},
			{
				path: 'search-recommended-plans',
				name: 'searchFeaturedTrips',
				component: SearchFeaturedTrips,
				meta: {
					routeTrackingId: 'SEARCH_TRIP_FEATURED',
					headerCollapse: true
				}
			},
			{
				path: '',
				name: 'partnershipHome',
				component: RequestPlan,
				meta: {
					routeTrackingId: 'PARTNERSHIP_HOME'
				}
			},
			{
				path: 'recommended-plan/:tripFeaturedId',
				name: 'tripFeaturedPreviewById',
				component: TripFeaturedPreview,
				meta: {
					routeTrackingId: 'TRIP_FEATURED_PREVIEW_ID',
					headerCollapse: true
				}
			},
			{
				path: 'recommended-plan-info/:tripFeaturedSlug',
				name: 'tripFeaturedPreviewBySlug',
				component: TripFeaturedPreview,
				meta: {
					routeTrackingId: 'TRIP_FEATURED_PREVIEW_SLUG',
					headerCollapse: true
				}
			},
			{
				path: 'search-points-of-interest',
				name: 'searchPointsOfInterest',
				component: SearchPointsOfInterest,
				meta: {
					routeTrackingId: 'SEARCH_POINT_OF_INTEREST',
					headerCollapse: true
				}
			},
			{
				path: 'search-narratives',
				name: 'searchNarratives',
				component: SearchNarratives,
				meta: {
					routeTrackingId: 'SEARCH_NARRATIVE',
					headerCollapse: true
				}
			},
			{
				path: 'my-plans',
				name: 'userPilgrimTrips',
				component: UserPilgrimTrips,
				beforeEnter: PrivateContextGuard.beforeRouterEnter,
				meta: {
					routeTrackingId: 'USER_TRIPS_PILGRIM_OWNED',
					headerCollapse: true
				}
			},
			{
				path: 'search-pilgrim-plans',
				name: 'searchPilgrimTrips',
				component: SearchPilgrimTrips,
				meta: {
					routeTrackingId: 'SEARCH_TRIP_PILGRIM',
					headerCollapse: true
				}
			},
			{
				path: 'create-new-plan',
				name: 'requestPlan',
				component: RequestPlan,
				meta: {
					routeTrackingId: 'PLANNER_FORM'
				}
			},
			{
				path: 'new-plan',
				name: 'tripPilgrimCreation',
				component: TripPilgrimCreation,
				meta: {
					routeTrackingId: 'TRIP_PILGRIM_CREATION',
					headerCollapse: true
				}
			},
			{
				path: 'narrative/:narrativeSlug',
				name: 'narrative',
				component: ViewNarrative
			},
			{
				path: 'pilgrim-plan/:tripPilgrimId',
				name: 'tripPilgrimPreview',
				component: TripPilgrimPreview,
				meta: {
					routeTrackingId: 'TRIP_PILGRIM_PREVIEW',
					headerCollapse: true
				}
			},
			{
				path: 'explore-on-map',
				name: 'exploreOnMap',
				component: ExploreOnMap,
				meta: {
					routeTrackingId: 'EXPLORE_ON_MAP',
					headerCollapse: false
				}
			},
			{
				path: 'download-app',
				name: 'downloadApp',
				component: DownloadApp,
				meta: {
					routeTrackingId: 'DOWNLOAD_APP',
					headerCollapse: true
				}
			},
			{
				path: 'vendors',
				name: 'vendors',
				component: Vendors,
				meta: {
					routeTrackingId: 'VENDORS_INFO',
					headerCollapse: true
				}
			},
			{
				path: '*',
				redirect: {name: 'partnershipHome'}
			}
		]
	},
	{
		path: '/',
		name: 'checkPartnership',
		component: CheckPartnership,
		meta: {
			routeTrackingId: 'CHECK_PARTNERSHIP'
		}
	},
	{
		path: '/register-account',
		name: 'registerAccount',
		component: RegisterAccount,
		beforeEnter: PublicContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'REGISTER_ACCOUNT'
		}
	},
	{
		path: '/contact/:languageIsoCode?',
		name: 'contact',
		component: ContactFormulary,
		meta: {
			routeTrackingId: 'CONTACT_FORMULARY'
		}
	},
	{
		path: '/forgot-password',
		name: 'forgotPassword',
		component: ForgotPassword,
		beforeEnter: PublicContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'FORGOT_PASSWORD'
		}
	},
	{
		path: '/forgot-password-verify-code/:authorizationId/:error?',
		name: 'forgotPasswordVerifyCode',
		component: ForgotPasswordVerifyCode,
		beforeEnter: PublicContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'FORGOT_PASSWORD_VERIFICATION'
		}
	},
	{
		path: '/password-reset/:authorizationId/:authorizationCode',
		name: 'passwordResetAuthorized',
		component: PasswordResetAuthorized,
		beforeEnter: PublicContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'RESET_PASSWORD'
		}
	},
	{
		path: '/created-account-successfully',
		name: 'createdAccountSuccessfully',
		component: CreatedAccountSuccessfully,
		beforeEnter: PublicContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'CREATED_ACCOUNT_SUCCESSFULLY'
		}
	},
	{
		path: '/error/:code/:message?',
		name: 'error',
		component: Error,
		meta: {
			routeTrackingId: 'ERROR_PAGE'
		}
	},
	{
		path: '/cookies-policy/:languageIsoCode?',
		name: 'cookiesPolicy',
		component: CookiesPolicy,
		meta: {
			routeTrackingId: 'COOKIES_POLICY'
		}
	},
	{
		path: '/copyright-policy/:languageIsoCode?',
		name: 'copyrightPolicy',
		component: CopyrightPolicy,
		meta: {
			routeTrackingId: 'COPYRIGHT_POLICY'
		}
	},
	{
		path: '/terms-of-use/:languageIsoCode?',
		name: 'termsOfUse',
		component: TermsOfUse,
		meta: {
			routeTrackingId: 'TERMS_OF_USE'
		}
	},
	{
		path: '/privacy-policy/:languageIsoCode?',
		name: 'privacyPolicy',
		component: PrivacyPolicy,
		meta: {
			routeTrackingId: 'PRIVACY_POLICY'
		}
	},
	{
		path: '/verify-account/:token',
		name: 'verifyAccount',
		component: VerifyAccount,
		meta: {
			routeTrackingId: 'VERIFY_ACCOUNT'
		}
	},
	{
		path: '/redirect-authenticated',
		name: 'redirectAuthenticated',
		component: RedirectAuthenticated,
		beforeEnter: PublicContextGuard.beforeRouterEnter
	},
	{
		path: '/update-user-data',
		name: 'updateUserData',
		component: UpdateUserData,
		beforeEnter: PrivateContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'USER_DATA_MODIFICATION'
		}
	},
	{
		path: '/logout',
		name: 'logout',
		component: LogoutView,
		beforeEnter: PrivateContextGuard.beforeRouterEnter,
		meta: {
			routeTrackingId: 'LOGOUT'
		}
	},
	{
		path: '/european-pilgrimage-routes',
		name: 'partnershipSelection',
		component: PartnershipSelection,
		meta: {
			routeTrackingId: 'PARTNERSHIP_SELECTION'
		}
	},
	{
		path: '*',
		redirect: {name: 'checkPartnership'}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: () => {
		return {x: 0, y: 0};
	}
});

export default router;
