import Vue from 'vue';
import App from './App.vue';
import router from './router';
import GvamVuePlugin from "rurallure-vue-plugin";
import parameters from "@/config/parameters";
import httpServicesAutoloader from "@/config/http-services-autoloader";
import VueSnip from 'vue-snip';
import VueMeta from "vue-meta";
import VueI18n from "vue-i18n";
import messages from "@/i18n/messages.js";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import {IndexDBHandler} from "@/utils/indexdb-handler";
import VueAnalytics from "vue-analytics";
import {
	BNavItem,
	BNavItemDropdown,
	BNavbarToggle,
	BNavbarBrand,
	BNavbarNav,
	BNavForm,
	BNavbar,
	BNav,
	BImg,
	BCollapse,
	BAvatar,
	BDropdownItem,
	BSidebar,
	BListGroup,
	BListGroupItem,
	BSpinner,
	BFormCheckbox,
	BLink,
	BModal,
	BDropdownDivider,
	BCarousel,
	BCarouselSlide,
	BFormRadio,
	VBModal,
	BRow,
	BCol,
	BButton,
	BSkeletonImg,
	BForm,
	BFormGroup,
	BFormInput,
	BContainer,
	BAlert,
	BPagination,
	BTab,
	BFormInvalidFeedback,
	BTabs,
	BSkeleton,
	BIconPersonLinesFill,
	BIconInfoCircleFill,
	BIconTrash,
	BIconCrop,
	BIconFacebook,
	BIconTwitter,
	BIconInstagram,
	BIconYoutube,
	BIconChevronDown,
	BIconFilter,
	BIconX,
	BIconGeoAlt,
	BIconPersonFill,
	BIconList,
	BIconGeoAltFill,
	BIconSearch,
	BIconMap,
	BIconSignpostSplit,
	BIconPlusCircle,
	BIconAward,
	BIconPeople,
	BIconListStars,
	BIconReceipt,
	BIconQuestionCircleFill,
	BIconCircle,
	BIconPencilSquare,
	BIconCalendarFill,
	BIconClockFill,
	BIconLink45deg,
	BIconAwardFill,
	BIconStar,
	BIconStarFill,
	BIconCircleFill,
	BIconCaretDownFill,
	BIconCaretUpFill,
	BIconTrashFill,
	BIconArrowClockwise,
	BIconCalendar,
	BIconJournal,
	BIconClock,
	BIconBoxArrowInUpRight,
	BIconCalendarWeekFill,
	BIconEnvelopeOpen,
	BIconCheckSquare,
	BIconMapFill,
	BIconViewList,
	BIconStickies,
	BIconCheckCircle,
	BIconLockFill,
	BIconGlobe,
	BIconArrowUpRightSquareFill,
	BIconKey,
	BIconPersonPlus,
	BIconInfoCircle,
	BIconChevronDoubleDown,
	BIconChevronDoubleUp,
	BIconChatSquareTextFill,
	VBToggle,
	BBadge,
	BFormDatepicker,
	BFormSelect,
	BIconCheck,
	BFormSelectOption,
	BInputGroup,
	BFormFile,
	BFormTextarea,
	BPopover,
	BEmbed,
	BOverlay,
	ModalPlugin,
	BIconCloudDownload
} from "bootstrap-vue";

Vue.component('b-overlay', BOverlay);
Vue.component('b-embed', BEmbed);
Vue.component('b-popover', BPopover);
Vue.component('b-form-textarea', BFormTextarea);
Vue.component('b-form-file', BFormFile);
Vue.component('b-form-select-option', BFormSelectOption);
Vue.component('b-input-group', BInputGroup);
Vue.component('b-form-select', BFormSelect);
Vue.component('b-badge', BBadge);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-datepicker', BFormDatepicker);
Vue.component('b-link', BLink);
Vue.component('b-modal', BModal);
Vue.directive('b-modal', VBModal);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-carousel', BCarousel);
Vue.component('b-carousel-slide', BCarouselSlide);
Vue.component('b-form-radio', BFormRadio);
Vue.directive('b-toggle', VBToggle);
Vue.component('b-nav-item', BNavItem);
Vue.component('b-nav-item-dropdown', BNavItemDropdown);
Vue.component('b-navbar-toggle', BNavbarToggle);
Vue.component('b-navbar-brand', BNavbarBrand);
Vue.component('b-navbar-nav', BNavbarNav);
Vue.component('b-nav-form', BNavForm);
Vue.component('b-navbar', BNavbar);
Vue.component('b-nav', BNav);
Vue.component('b-img', BImg);
Vue.component('b-collapse', BCollapse);
Vue.component('b-avatar', BAvatar);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-sidebar', BSidebar);
Vue.component('b-list-group', BListGroup);
Vue.component('b-list-group-item', BListGroupItem);
Vue.component('b-spinner', BSpinner);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-button', BButton);
Vue.component('b-skeleton', BSkeleton);
Vue.component('b-skeleton-img', BSkeletonImg);
Vue.component('b-form', BForm);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-input', BFormInput);
Vue.component('b-container', BContainer);
Vue.component('b-alert', BAlert);
Vue.component('b-pagination', BPagination);
Vue.component('b-tabs', BTabs);
Vue.component('b-tab', BTab);
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback);

Vue.component('b-icon-person-lines-fill', BIconPersonLinesFill);
Vue.component('b-icon-info-circle-fill', BIconInfoCircleFill);
Vue.component('b-icon-trash', BIconTrash);
Vue.component('b-icon-crop', BIconCrop);
Vue.component('b-icon-facebook', BIconFacebook);
Vue.component('b-icon-twitter', BIconTwitter);
Vue.component('b-icon-instagram', BIconInstagram);
Vue.component('b-icon-youtube', BIconYoutube);
Vue.component('b-icon-chevron-down', BIconChevronDown);
Vue.component('b-icon-filter', BIconFilter);
Vue.component('b-icon-x', BIconX);
Vue.component('b-icon-geo-alt', BIconGeoAlt);
Vue.component('b-icon-person-fill', BIconPersonFill);
Vue.component('b-icon-list', BIconList);
Vue.component('b-icon-geo-alt-fill', BIconGeoAltFill);
Vue.component('b-icon-search', BIconSearch);
Vue.component('b-icon-map', BIconMap);
Vue.component('b-icon-signpost-split', BIconSignpostSplit);
Vue.component('b-icon-plus-circle', BIconPlusCircle);
Vue.component('b-icon-award', BIconAward);
Vue.component('b-icon-people', BIconPeople);
Vue.component('b-icon-list-stars', BIconListStars);
Vue.component('b-icon-receipt', BIconReceipt);
Vue.component('b-icon-question-circle-fill', BIconQuestionCircleFill);
Vue.component('b-icon-circle', BIconCircle);
Vue.component('b-icon-pencil-square', BIconPencilSquare);
Vue.component('b-icon-calendar-fill', BIconCalendarFill);
Vue.component('b-icon-clock-fill', BIconClockFill);
Vue.component('b-icon-link-45deg', BIconLink45deg);
Vue.component('b-icon-award-fill', BIconAwardFill);
Vue.component('b-icon-star', BIconStar);
Vue.component('b-icon-star-fill', BIconStarFill);
Vue.component('b-icon-circle-fill', BIconCircleFill);
Vue.component('b-icon-caret-down-fill', BIconCaretDownFill);
Vue.component('b-icon-caret-up-fill', BIconCaretUpFill);
Vue.component("b-icon-trash-fill", BIconTrashFill);
Vue.component("b-icon-arrow-clockwise", BIconArrowClockwise);
Vue.component("b-icon-calendar", BIconCalendar);
Vue.component("b-icon-journal", BIconJournal);
Vue.component("b-icon-clock", BIconClock);
Vue.component("b-icon-box-arrow-in-up-right", BIconBoxArrowInUpRight);
Vue.component("b-icon-calendar-week-fill", BIconCalendarWeekFill);
Vue.component("b-icon-envelope-open", BIconEnvelopeOpen);
Vue.component("b-icon-check-square", BIconCheckSquare);
Vue.component("b-icon-map-fill", BIconMapFill);
Vue.component("b-icon-view-list", BIconViewList);
Vue.component("b-icon-stickies", BIconStickies);
Vue.component("b-icon-check-circle", BIconCheckCircle);
Vue.component("b-icon-lock-fill", BIconLockFill);
Vue.component("b-icon-globe", BIconGlobe);
Vue.component("b-icon-arrow-up-right-square-fill", BIconArrowUpRightSquareFill);
Vue.component("b-icon-key", BIconKey);
Vue.component("b-icon-person-plus", BIconPersonPlus);
Vue.component('b-icon-info-circle', BIconInfoCircle);
Vue.component('b-icon-check', BIconCheck);
Vue.component('b-icon-cloud-download', BIconCloudDownload);
Vue.component('b-icon-chevron-double-down', BIconChevronDoubleDown);
Vue.component('b-icon-chevron-double-up', BIconChevronDoubleUp);
Vue.component('b-icon-chat-square-text-fill', BIconChatSquareTextFill);

Vue.use(ModalPlugin);

Vue.config.productionTip = false;
Vue.use(VueSnip);
Vue.use(VueMeta, parameters.meta);
Vue.use(VueI18n);
Vue.use(VueAnalytics, {
	id: parameters.googleAnalyticsId
});
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap);

Vue.use(GvamVuePlugin, {
	oAuth2Configuration: parameters.oauth2Configuration,
	cipherKey: parameters.cipherKey
});

declare module 'vue/types/vue' {
  interface Vue {
    $scrollToTop: () => void;
    $indexDB: IndexDBHandler;
  }
}
Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);
Vue.prototype.$indexDB = new IndexDBHandler(parameters.idb);

httpServicesAutoloader(Vue);

const i18n = new VueI18n({
	locale: parameters.i18n.default,
	messages
});

new Vue({
	router,
	i18n,
	render: h => h(App)
}).$mount('#app');
