import Vue, {VueConstructor} from "vue";
import GetUrlForAuthenticateService from "@/services/Google/GetUrlForAuthenticateService";
import HttpService from "rurallure-vue-plugin/src/HttpService";
import OAuth2 from "rurallure-vue-plugin/src/OAuth2";
import BrowserStorage from "rurallure-vue-plugin/src/BrowserStorage";
import GetUserDataService from "@/services/User/GetUserDataService";
import RegisterService from "@/services/User/RegisterService";
import GetAllCountriesAvailableService from "@/services/Country/GetAllCountriesAvailableService";
import VerifyAccountService from "@/services/AccountVerification/VerifyAccountService";
import CreateForgotPasswordAuthorizationService
	from "@/services/ForgotPasswordAuthorization/CreateForgotPasswordAuthorizationService";
import VerifyForgotPasswordAuthorizationService
	from "@/services/ForgotPasswordAuthorization/VerifyForgotPasswordAuthorizationService";
import ChangePasswordAuthorizedService from "@/services/ForgotPasswordAuthorization/ChangePasswordAuthorizedService";
import ExpirationForgotPasswordAuthorizationService
	from "@/services/ForgotPasswordAuthorization/ExpirationForgotPasswordAuthorizationService";
import GetPartnershipDataService from "@/services/Partnership/GetPartnershipDataService";
import VendorAuthorizationUrlService from "@/services/User/VendorAuthorizationUrlService";
import GetPartnershipListService from "@/services/Partnership/GetPartnershipListService";
import GetListPointOfInterestService from "@/services/PointOfInterest/GetListPointOfInterestService";
import UploadCroppedUserImageService from "@/services/FileUploaded/UploadCroppedUserImageService";
import GetListTripService from "@/services/Trip/GetListTripService";
import GetAllPreferencesService from "@/services/Preferences/GetAllPreferencesService";
import GetAllLanguagesAvailableService from "@/services/Languages/GetAllLanguagesAvailableService";
import UpdateUserDataService from "@/services/User/UpdateUserDataService";
import SendContactFormRequestedService from "@/services/ContactFormRequested/SendContactFormRequestedService";
import GetPilgrimTripsService from "@/services/Trip/GetPilgrimTripsService";
import SearchPointOfInterestService from "@/services/PointOfInterest/SearchPointOfInterestService";
import SearchNarrativeSeriesService from "@/services/Narratives/SearchNarrativeSeriesService";
import GetNarrativeService from "@/services/Narratives/GetNarrativeService";
import SearchPilgrimsTripsService from "@/services/Trip/SearchPilgrimsTripsService";
import SearchFeaturedTripsService from "@/services/Trip/SearchFeaturedTripsService";
import GetAllMainFeaturedService from "@/services/Trip/GetAllMainFeaturedService";
import CreateTripPilgrimService from "@/services/Trip/CreateTripPilgrimService";
import GetTripFeaturedByIdService from "@/services/Trip/GetTripFeaturedByIdService";
import GetMainFeaturedTripsByPartnershipService from "@/services/Trip/GetMainFeaturedTripsByPartnershipService";
import GetPointOfInterestBySlugService from "@/services/PointOfInterest/GetPointOfInterestBySlugService";
import GetTripFeaturedBySlugService from "@/services/Trip/GetTripFeaturedBySlugService";
import GetAllTransportsService from "@/services/Transport/GetAllTransportsService";
import GetAllCategoriesService from "@/services/Categories/GetAllCategoriesService";
import GeocodingSearchService from "@/services/Geocoding/GeocodingSearchService";
import GeocodingCoordinatesService from "@/services/Geocoding/GeocodingCoordinatesService";
import GetPrivacyLevelsService from "@/services/PrivacyLevels/GetPrivacyLevelsService";
import NewPlanService from "@/services/Planner/NewPlanService";
import NewPlanFromFeaturedService from "@/services/Planner/NewPlanFromFeaturedService";
import GetPlanLocalitiesService from "@/services/Planner/GetPlanLocalitiesService";
import PlannerRouteDataForChangesService from "@/services/Planner/RouteDataForChangesService";
import RouteExtendedCalculatorService from "@/services/Planner/RouteExtendedCalculatorService";
import GetTripPilgrimService from "@/services/Trip/GetTripPilgrimService";
import CountNewNotificationsService from "@/services/Notification/CountNewNotificationsService";
import GetNotificationsService from "@/services/Notification/GetNotificationsService";
import MarkNotificationAsViewedService from "@/services/Notification/MarkNotificationAsViewedService";
import DeleteTripPilgrimService from "@/services/Trip/DeleteTripPilgrimService";
import CreateTripPilgrimJoinRequestService from "@/services/TripPilgrimJoinRequest/CreateTripPilgrimJoinRequestService";
import DeleteParticipantByOwnerService from "@/services/Trip/DeleteParticipantByOwnerService";
import GetPilgrimAsParticipantService from "@/services/Trip/GetPilgrimAsParticipantService";
import DeleteParticipantBySelfService from "@/services/Trip/DeleteParticipantBySelfService";
import UpdateStateService from "@/services/TripPilgrimJoinRequest/UpdateStateService";
import SearchBoundingBoxService from "@/services/PointOfInterest/SearchBoundingBoxService";
import SendInteractionsService from "@/services/ClientTracking/SendInteractionsService";
import GetNearbyService from "@/services/PointOfInterest/GetNearbyService";
import GetDetoursListService from "@/services/Planner/GetDetoursListService";
import DeleteAccountService from "@/services/User/DeleteAccountService";

declare module 'vue/types/vue' {
    interface Vue {
        $httpService: HttpService;
        $oAuth2: OAuth2;
        $browserStorage: BrowserStorage;
        $getUrlForAuthenticateService: GetUrlForAuthenticateService;
        $getUserDataService: GetUserDataService;
        $registerService: RegisterService;
        $getAllCountriesAvailableService: GetAllCountriesAvailableService;
        $getAllLanguagesAvailable: GetAllLanguagesAvailableService;
        $verifyAccountService: VerifyAccountService;
        $createForgotPasswordAuthorizationService: CreateForgotPasswordAuthorizationService;
        $verifyForgotPasswordAuthorizationService: VerifyForgotPasswordAuthorizationService;
        $changePasswordAuthorizedService: ChangePasswordAuthorizedService;
        $expirationForgotPasswordAuthorizationService: ExpirationForgotPasswordAuthorizationService;
        $getPartnershipDataService: GetPartnershipDataService;
        $vendorAuthorizationUrlService: VendorAuthorizationUrlService;
        $getPartnershipListService: GetPartnershipListService;
        $getListPointOfInterest: GetListPointOfInterestService;
        $uploadCroppedUserImageService: UploadCroppedUserImageService;
        $getListTripService: GetListTripService;
        $getAllPreferencesService: GetAllPreferencesService;
        $updateUserDataService: UpdateUserDataService;
        $sendContactFormRequestedService: SendContactFormRequestedService;
        $getPilgrimTripsService: GetPilgrimTripsService;
        $searchPointOfInterestService: SearchPointOfInterestService;
        $getNarrativeService: GetNarrativeService;
        $searchNarrativeSeriesService: SearchNarrativeSeriesService;
        $searchPilgrimsTripsService: SearchPilgrimsTripsService;
        $searchFeaturedTripsService: SearchFeaturedTripsService;
        $getAllMainFeaturedService: GetAllMainFeaturedService;
        $createTripPilgrimService: CreateTripPilgrimService;
        $getTripFeaturedByIdService: GetTripFeaturedByIdService;
        $getMainFeaturedTripsByPartnershipService: GetMainFeaturedTripsByPartnershipService;
        $getPointOfInterestBySlugService: GetPointOfInterestBySlugService;
        $getTripFeaturedBySlugService: GetTripFeaturedBySlugService;
        $getAllTransportsService: GetAllTransportsService;
        $getAllCategoriesService: GetAllCategoriesService;
        $geocodingSearchService: GeocodingSearchService;
        $geocodingCoordinatesService: GeocodingCoordinatesService;
        $getPrivacyLevelsService: GetPrivacyLevelsService;
        $newPlanService: NewPlanService;
        $newPlanFromFeaturedService: NewPlanFromFeaturedService;
        $getPlanLocalitiesService: GetPlanLocalitiesService;
        $plannerRouteDataForChangesService: PlannerRouteDataForChangesService;
        $routeExtendedCalculatorService: RouteExtendedCalculatorService;
        $getTripPilgrimService: GetTripPilgrimService;
        $countNewNotification: CountNewNotificationsService;
        $getNotificationService: GetNotificationsService;
        $markNotificationAsViewed: MarkNotificationAsViewedService;
        $deleteTripPilgrimService: DeleteTripPilgrimService;
        $createTripPilgrimJoinRequestService: CreateTripPilgrimJoinRequestService;
        $deleteParticipantByOwnerService: DeleteParticipantByOwnerService;
        $getPilgrimAsParticipantService: GetPilgrimAsParticipantService;
        $deleteParticipantBySelfService: DeleteParticipantBySelfService;
        $updateStateService: UpdateStateService;
        $searchBoundingBoxService: SearchBoundingBoxService;
        $sendInteractionsService: SendInteractionsService;
        $getNearbyService: GetNearbyService;
        $getDetoursListService: GetDetoursListService;
		$deleteAccountService: DeleteAccountService;
    }
}

export default (vue: VueConstructor<Vue>): void => {

	vue.prototype.$getUrlForAuthenticateService = new GetUrlForAuthenticateService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getUserDataService = new GetUserDataService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$registerService = new RegisterService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getAllCountriesAvailableService = new GetAllCountriesAvailableService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getAllLanguagesAvailable = new GetAllLanguagesAvailableService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$verifyAccountService = new VerifyAccountService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$createForgotPasswordAuthorizationService = new CreateForgotPasswordAuthorizationService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$verifyForgotPasswordAuthorizationService = new VerifyForgotPasswordAuthorizationService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$changePasswordAuthorizedService = new ChangePasswordAuthorizedService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$expirationForgotPasswordAuthorizationService = new ExpirationForgotPasswordAuthorizationService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getPartnershipDataService = new GetPartnershipDataService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$vendorAuthorizationUrlService = new VendorAuthorizationUrlService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getPartnershipListService = new GetPartnershipListService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getListPointOfInterest = new GetListPointOfInterestService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$uploadCroppedUserImageService = new UploadCroppedUserImageService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getListTripService = new GetListTripService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getAllPreferencesService = new GetAllPreferencesService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$updateUserDataService = new UpdateUserDataService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$sendContactFormRequestedService = new SendContactFormRequestedService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getPilgrimTripsService = new GetPilgrimTripsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$searchNarrativeSeriesService = new SearchNarrativeSeriesService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$searchPointOfInterestService = new SearchPointOfInterestService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$searchPilgrimsTripsService = new SearchPilgrimsTripsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$searchFeaturedTripsService = new SearchFeaturedTripsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getAllMainFeaturedService = new GetAllMainFeaturedService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$createTripPilgrimService = new CreateTripPilgrimService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getTripFeaturedByIdService = new GetTripFeaturedByIdService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getMainFeaturedTripsByPartnershipService = new GetMainFeaturedTripsByPartnershipService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getPointOfInterestBySlugService = new GetPointOfInterestBySlugService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getTripFeaturedBySlugService = new GetTripFeaturedBySlugService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getAllTransportsService = new GetAllTransportsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getAllCategoriesService = new GetAllCategoriesService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getPrivacyLevelsService = new GetPrivacyLevelsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getTripPilgrimService = new GetTripPilgrimService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$countNewNotification = new CountNewNotificationsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getNotificationService = new GetNotificationsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$markNotificationAsViewed = new MarkNotificationAsViewedService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$deleteTripPilgrimService = new DeleteTripPilgrimService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$createTripPilgrimJoinRequestService = new CreateTripPilgrimJoinRequestService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$deleteParticipantByOwnerService = new DeleteParticipantByOwnerService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getPilgrimAsParticipantService = new GetPilgrimAsParticipantService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$deleteParticipantBySelfService = new DeleteParticipantBySelfService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$updateStateService = new UpdateStateService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$searchBoundingBoxService = new SearchBoundingBoxService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$sendInteractionsService = new SendInteractionsService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getNearbyService = new GetNearbyService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);

	vue.prototype.$getNarrativeService = new GetNarrativeService(
		vue.prototype.$httpService
	);

	vue.prototype.$geocodingCoordinatesService = new GeocodingCoordinatesService(
		vue.prototype.$httpService
	);

	vue.prototype.$geocodingSearchService = new GeocodingSearchService(
		vue.prototype.$httpService
	);

	vue.prototype.$newPlanService = new NewPlanService(
		vue.prototype.$httpService
	);

	vue.prototype.$newPlanFromFeaturedService = new NewPlanFromFeaturedService(
		vue.prototype.$httpService
	);

	vue.prototype.$getPlanLocalitiesService = new GetPlanLocalitiesService(
		vue.prototype.$httpService
	);

	vue.prototype.$plannerRouteDataForChangesService = new PlannerRouteDataForChangesService(
		vue.prototype.$httpService
	);

	vue.prototype.$routeExtendedCalculatorService = new RouteExtendedCalculatorService(
		vue.prototype.$httpService
	);

	vue.prototype.$getDetoursListService = new GetDetoursListService(
		vue.prototype.$httpService
	);

	vue.prototype.$deleteAccountService = new DeleteAccountService(
		vue.prototype.$httpService,
		vue.prototype.$browserStorage,
		vue.prototype.$oAuth2
	);
};
