"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NameHexColorGeneratorHelper = /** @class */ (function () {
    function NameHexColorGeneratorHelper() {
        this.colors = {
            'a': 0x923f3f,
            'b': 0x92593f,
            'c': 0x92743f,
            'd': 0x91923f,
            'e': 0x72923f,
            'f': 0x40923f,
            'g': 0x3f9275,
            'h': 0x3f8792,
            'i': 0x3f6792,
            'j': 0x3f4b92,
            'k': 0x473f92,
            'l': 0x5c3f92,
            'm': 0x703f92,
            'n': 0x8d3f92,
            'o': 0x923f7c,
            'p': 0x923f62,
            'q': 0x923f4f,
            'r': 0x6b443d,
            's': 0x636b3d,
            't': 0x416b3d,
            'u': 0x3d6b62,
            'v': 0x3d576b,
            'w': 0x3d446b,
            'x': 0x4c3d6b,
            'y': 0x653d6b,
            'z': 0x4e2d2d
        };
    }
    NameHexColorGeneratorHelper.prototype.findColorByFistLetter = function (letter) {
        if (this.colors[letter]) {
            return this.colors[letter];
        }
        return 0x444444;
    };
    NameHexColorGeneratorHelper.prototype.getCode = function (name, surname) {
        var hex = this.findColorByFistLetter(name.charAt(0).toLowerCase());
        var coefficient = name.length * surname.length;
        return '#' + ('00000' + (hex - (coefficient * 2)).toString(16)).substr(-6);
    };
    return NameHexColorGeneratorHelper;
}());
exports.default = NameHexColorGeneratorHelper;
