const parameters = {
	serverApplicationHost: process.env.VUE_APP_APPLICATION_SERVER_HOST,
	oauth2Configuration: {
		host: process.env.VUE_APP_APPLICATION_SERVER_HOST,
		clientId: process.env.VUE_APP_OAUTH2_CLIENT_ID,
		clientSecret: process.env.VUE_APP_OAUTH2_CLIENT_SECRET,
		scopes: [
			'AUTH-GOOGLE',
			'REGISTER-USER',
			'READ-USER-DATA',
			'FORGOT-PASSWORD-AUTHORIZATION',
			'READ-PARTNERSHIP-DATA',
			'READ-ACTIVITY-POI',
			'READ-FEATURED-POI',
			'UPLOAD-IMAGE',
			'READ-FEATURED-TRIP',
			'READ-PILGRIM-TRIP',
			'EDIT-USER-DATA',
			'CONTACT-FORM',
			'READ-NOTIFICATIONS',
			'CREATE-PILGRIM-TRIP',
			'EDIT-PILGRIM-TRIP',
			'DELETE-PILGRIM-TRIP'
		]
	},
	host: process.env.VUE_APP_HOST,
	i18n: {
		default: 'en'
	},
	cdn: {
		host: process.env.VUE_APP_BUCKET_S3_URL,
		directories: {
			'image/jpeg': 'images',
			'video/mp4': 'video',
			'audio/mp3': 'audio'
		},
		languageFlagsDirectory: 'language-flags'
	},
	vendorCompanyWebClient: {
		oAuth2: {
			clientId: process.env.VUE_APP_OAUTH2_VENDOR_COMPANY_CLIENT_ID,
			redirectUri: process.env.VUE_APP_OAUTH2_VENDOR_COMPANY_REDIRECT_URI,
			scopes: [
				'READ-ACTIVITY-POI',
				'READ-VENDOR',
				'EDIT-VENDOR',
				'EDIT-ACTIVITY-POI',
				'READ-USER-DATA',
				'UPLOAD-IMAGE',
				'DELETE-ACTIVITY-POI',
				'READ-PARTNERSHIP-DATA',
				'CREATE-VENDOR',
				'CREATE-ACTIVITY-POI',
				'READ-NOTIFICATIONS',
				'DELETE-VENDOR',
				'CONTACT-FORM'
			]
		}
	},
	cipherKey: '-~`46@&aS6<).G`F[5T{,.=}Ad!,~h;',
	recaptcha: {
		siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
	},
	planner: {
		host: process.env.VUE_APP_PLANNER_SERVER_HOST,
		defaultTravelingHoursPerDay: 8
	},
	narratives: {
		host: process.env.VUE_APP_NARRATIVES_SERVER_HOST,
		host_backoffice: process.env.VUE_APP_NARRATIVES_BACKOFFICE_URL
	},
	mapConfiguration: {
		tileServerUrl: process.env.VUE_APP_TILE_SERVER,
		partnershipRoutesTileServerUrl: process.env.VUE_APP_PARTNERSHIP_ROUTE_TILE_SERVER,
		attribution: "&copy; <a target='_blank' href='https://osm.org/copyright'>OpenStreetMap</a> contributors | " +
            "&copy; <a target='_blank' href='https://openrouteservice.org'>openrouteservice.org</a> by HeiGIT",
		pathStyles: {
			partnershipRoutes: {
				stroke: true,
				color: "#c78400",
				weight: 3,
				opacity: 0.8,
			},
			partnershipRoutesBackground: {
				stroke: true,
				color: "#7E7B68",
				weight: 2,
				opacity: 0.7,
				dashArray: '4'
			},
			officialRoutes: {
				stroke: true,
				color: "#c78400",
				weight: 3,
				opacity: 0.9,
			},
			detours: {
				stroke: true,
				color: "#7e5400",
				weight: 3,
				opacity: 0.9,
				dashArray: '4'
			}
		}
	},
	meta: {
		keyName: 'metaInfo',
		attribute: 'data-rurallure-meta',
		ssrAttribute: 'data-vue-meta-server-rendered',
		tagIDKeyName: 'vmid',
		refreshOnceOnNavigation: true
	},
	projectWebAddress: "https://rurallure.eu",
	geocoding: {
		host: process.env.VUE_APP_PELIAS_API_HOST,
		apiKey: process.env.VUE_APP_PELIAS_API_KEY
	},
	idb: {
		version: 1,
		database: 'rurAllure',
		schema: [
			{name: 'geocodingReverse'}
		]
	},
	clientTracking: {
		interactionTypes: {
			viewNavigation: 'ViewNavigation',
			searchText: 'SearchText',
			pointOfInterestViewed: 'PointOfInterestViewed'
		}
	},
	googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
};
export default parameters;
