import { render, staticRenderFns } from "./PointOfInterestCard.html?vue&type=template&id=71a59033&scoped=true&"
import script from "./PointOfInterestCard.vue?vue&type=script&lang=ts&"
export * from "./PointOfInterestCard.vue?vue&type=script&lang=ts&"
import style0 from "./PointOfInterestCard.scss?vue&type=style&index=0&id=71a59033&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a59033",
  null
  
)

export default component.exports