"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ArrayChunkHelper = /** @class */ (function () {
    function ArrayChunkHelper() {
    }
    ArrayChunkHelper.chunk = function (array, size) {
        var chunked = [];
        for (var i = 0; i < array.length; i += size) {
            var chunk = array.slice(i, i + size);
            chunked.push(chunk);
        }
        return chunked;
    };
    return ArrayChunkHelper;
}());
exports.default = ArrayChunkHelper;
