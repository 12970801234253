import {IDBPDatabase, openDB} from "idb";

export type IndexDBTable = {
    name: string;
}
export type IndexDBConfiguration = {
    database: string;
    version: number;
    schema: IndexDBTable[];
}
export type StoredValue<T> = {
    id: string;
    value: T;
}

export class IndexDBHandler {

    private connection: IDBPDatabase;

    public constructor(
        private config: IndexDBConfiguration
    ) {
    }

    public async createObjectStorage(): Promise<void> {
    	try {
    		this.connection = await openDB(
    			this.config.database,
    			this.config.version,
    			{
    				upgrade: (database: IDBPDatabase<unknown>) => {
    					for (const table of this.config.schema) {
    						if (database.objectStoreNames.contains(table.name)) {
    							continue;
    						}
    						database.createObjectStore(table.name, {keyPath: 'id'});
    					}
    				}
    			}
    		);
    	} catch (error) {
    		throw new Error(error as string);
    	}
    }

    public async getValue<T>(table: string, id: string):  Promise<StoredValue<T>> {
    	const transaction = this.connection.transaction(table, 'readonly');
    	const result = await transaction.objectStore(table).get(id);

    	return result;
    }

    public async putValue<T>(table: string, value: StoredValue<T>): Promise<void> {
    	const transaction = this.connection.transaction(table, 'readwrite');
    	await transaction.objectStore(table).put(value);
    }
}
