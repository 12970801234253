import { render, staticRenderFns } from "./../LegalTexts.html?vue&type=template&id=4077be80&lang=html&"
import script from "./PrivacyPolicy.vue?vue&type=script&lang=ts&"
export * from "./PrivacyPolicy.vue?vue&type=script&lang=ts&"
import style0 from "./../LegalTexts.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports