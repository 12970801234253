import {NavigationGuardNext, Route} from "vue-router";
import Vue from "vue";

export default class PrivateContextGuard {
	public static async beforeRouterEnter(from: Route, to: Route, next: NavigationGuardNext): Promise<void> {
		try {
			const tokenData = await Vue.prototype.$oAuth2.getUserTokenData();
			if (tokenData !== null) {
				next();
			} else {
				next({name: 'checkPartnership'});
			}
		} catch (error) {
			next({name: 'error', params: {code: '500'}});
		}
	}
}