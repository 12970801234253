import { render, staticRenderFns } from "./DownloadAppBanner.html?vue&type=template&id=03afe0e2&scoped=true&lang=html&"
import script from "./DownloadAppBanner.vue?vue&type=script&lang=ts&"
export * from "./DownloadAppBanner.vue?vue&type=script&lang=ts&"
import style0 from "./DownloadAppBanner.vue?vue&type=style&index=0&id=03afe0e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03afe0e2",
  null
  
)

export default component.exports