"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NarrativesHttpService = /** @class */ (function () {
    function NarrativesHttpService($httpService
    // eslint-disable-next-line no-empty-function
    ) {
        this.$httpService = $httpService;
    }
    return NarrativesHttpService;
}());
exports.default = NarrativesHttpService;
