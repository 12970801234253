


import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import NavigationBar from "@/components/navigation-bar/NavigationBar.vue";
import CookiesMessage from "@/components/cookies-message/CookiesMessage.vue";
import Footer from "@/components/footer/Footer.vue";
import * as FingerPrintJs from "@fingerprintjs/fingerprintjs";
import {ClientTrackingHttpModel, InteractionHttpModel} from "rurallure-ts-models";
import parameters from "@/config/parameters";
import {Route} from "vue-router";
import {ViewNavigationInteraction} from "@/model/client_tracking";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import currentParseFormat from "dayjs/plugin/customParseFormat";

@Component({
	name: 'App',
	components: {
		NavigationBar,
		CookiesMessage,
		Footer
	},
	metaInfo() {

		const projectDescription = 'Promotion of rural museums and heritage sites in the vicinity of European pilgrimage routes About rurAllure In Europe, hundreds of thousands of people set out each year from their homes or from popular starting points';

		return {
			meta: [
				{httpEquiv:'Content-Type', content:'text/html;charset=UTF-8'},
				{name: 'og:type', content: 'website'},
				{name: 'description', content: projectDescription},
				{property: 'og:description', content: projectDescription},
				{name: 'robots', content:'index,follow'},
				{httpEquiv: 'content-language', content: this.$i18n.locale},
				{property: 'og:site_name', content: 'rurAllure'}
			],
			link: [],
			htmlAttrs: {
				lang: this.$i18n.locale
			}
		};
	}
})
export default class App extends Vue {

  private userSecondsStayOnView: number;
  private updateUserSecondsInterval: number;
  private clientTracking: ClientTrackingHttpModel;
  private readonly numberInteractionsPerRequest: number = 10;

  public constructor() {
  	super();
  	dayjs.extend(utc);
  	dayjs.extend(currentParseFormat);
  	this.userSecondsStayOnView = 1;
  	this.clientTracking = {
  		browser_fingerprint: null,
  		interactions: []
  	};
  }

  @Watch('$route')
  public onRouteChange(to: Route, from: Route): void {
  	if (to.path !== from.path) {
  		this.$ga.page(to.path);
  	}
  	if (to.meta === undefined || from.meta === undefined) {
  		return;
  	}
  	if (to.meta.routeTrackingId === undefined || from.meta.routeTrackingId === undefined) {
  		return;
  	}
  	const userNavigateInteraction: ViewNavigationInteraction = {
  		to_route_id: to.meta.routeTrackingId,
  		from_route_id: from.meta.routeTrackingId,
  		user_seconds_stay_on_view: this.userSecondsStayOnView,
  		client_utc_date: dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
  		locale: this.$i18n.locale,
  		partnership_slug: this.$route.params?.partnershipSlug ?? null,
  		type: parameters.clientTracking.interactionTypes.viewNavigation
  	};
  	this.$root.$emit('USER_INTERACTION' , userNavigateInteraction);
  }

  public beforeCreate(): void {
  	if (this.$browserStorage.getCookie('lang') !== null) {
  		this.$i18n.locale = this.$browserStorage.getCookie('lang') as string;
  	}
  	document.body.style.backgroundImage = `url(${require('@/assets/images/bg_map.svg')})`;
  }

  public mounted(): void {

  	FingerPrintJs.load()
  		.then(
  			fingerPrint => fingerPrint.get()
  		)
  		.then(
  			result => {
  				this.clientTracking.browser_fingerprint = result.visitorId;
  			}
  		);

  	this.$root.$on('USER_INTERACTION', (interaction: InteractionHttpModel) => {
  		this.clientTracking.interactions.push(interaction);
  		if (this.clientTracking.interactions.length >= this.numberInteractionsPerRequest) {
  			this.sendClientTracking();
  		}
  		if (interaction.type === parameters.clientTracking.interactionTypes.viewNavigation) {
  			this.userSecondsStayOnView = 1;
  		}
  	});

  	this.updateUserSecondsInterval = setInterval(() => {
  		this.userSecondsStayOnView += 1;
  	}, 1000);
  }

  public unmounted(): void {
  	clearInterval(this.updateUserSecondsInterval);
  }

  private sendClientTracking(): void {
  	if (this.clientTracking.browser_fingerprint === null) {
  		throw new Error('Missing browser fingerprint');
  	}
  	this.$sendInteractionsService.request(this.clientTracking).finally(
  		() => {
  			this.clientTracking.interactions = [];
  		}
  	);
  }
}
