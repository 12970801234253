import {NavigationGuardNext, Route} from "vue-router";
import Vue from "vue";

export default class PublicContextGuard {
	public static async beforeRouterEnter(from: Route, to: Route, next: NavigationGuardNext): Promise<void> {
		if (await Vue.prototype.$oAuth2.getUserTokenData() !== null) {
			next({name: 'checkPartnership'});
		} else {
			next();
		}
	}
}