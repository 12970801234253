"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HaversineDistanceCalculatorHelper = void 0;
var HaversineDistanceCalculatorHelper = /** @class */ (function () {
    function HaversineDistanceCalculatorHelper() {
    }
    HaversineDistanceCalculatorHelper.calculate = function (start, end) {
        var startLatRadians = start.latitude * (Math.PI / 180);
        var startLonRadians = start.longitude * (Math.PI / 180);
        var endLatRadians = end.latitude * (Math.PI / 180);
        var endLonRadians = end.longitude * (Math.PI / 180);
        var latitudeDelta = endLatRadians - startLatRadians;
        var longitudeDelta = endLonRadians - startLonRadians;
        var angle = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(latitudeDelta / 2), 2) + Math.cos(startLatRadians) * Math.cos(endLatRadians) * Math.pow(Math.sin(longitudeDelta / 2), 2)));
        return Math.floor(angle * 6371e3);
    };
    return HaversineDistanceCalculatorHelper;
}());
exports.HaversineDistanceCalculatorHelper = HaversineDistanceCalculatorHelper;
